import React,  { useState } from "react";
import Header from "../components/header/header";
import Helmet from "react-helmet";

export default function About(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="index">
      <Helmet>
          <meta charSet="utf-8" />
          <title>About</title>
      </Helmet>
      <Header page='about' open={open} setOpen={setOpen}/>
      <div className='content'>
      <img src='/image2.jpg' className='lol'></img>
      <div className='about'>
      <p className='indent'>
			Julia Edwards is a poet, editor, and educator from New York. Her work appears in POETRY, Bat City Review, Diode, among others. Her debut manuscript, The Royal You, was chosen as a finalist for the 2022 Jake Adam York prize. She was selected as a 2021 visiting poet in the Working Poets Project at The Dalton School. Julia holds an MFA from the University of North Carolina, Greensboro, where she served as poetry editor for The Greensboro Review. She currently lives in Brooklyn, where she co-edits the Girl Blood Info zine series.        </p>
        </div>
      </div>
    </div>
  )
}
